<ng-container *ngIf="!hasNoAverages; else nothing">
  <ng-container *ngIf="!hasNoAveragesAnonymity; else anonymous">
    <div class="z-fx-col z-fx-fill">
      <div class="z-fx-col z-fx-auto scrollbar-container">
        <ng-scrollbar class="z-fx-col z-fx-fill">
          <div class="z-fx z-fx-center-between z-fx-wrap container">
            <div
              *ngFor="let item of chartData; trackBy: trackById"
              class="z-fx-col z-fx-start-center average-item"
              [style.color]="colorScale(item?.group?.index)"
              [class.aggregate]="item?.group?.key === 'aggregate'"
            >
              <div class="number-display zef-text-d{{ item?.group?.key === 'aggregate' ? '1' : '2' }}">
                <ng-container *ngIf="item?.overallAverage !== null; else noData">
                  <span
                    numberTransition
                    class="average-number"
                    [data]="item?.overallAverage"
                    [duration]="750"
                    [showAlwaysDecimals]="showAlwaysDecimals"
                  ></span>
                </ng-container>
                <ng-template #noData>
                  <span class="average-number">-</span>
                </ng-template>
              </div>
              <div class="average-label zef-text-center zef-text-p3">
                {{ item?.group?.title }}
              </div>
              <div class="average-respondents">
                <mat-icon class="icon">contact</mat-icon>
                <span
                  numberTransition
                  class="average-respondent-count number"
                  [data]="item?.group?.count"
                  [duration]="transitionDuration"
                ></span>
              </div>
            </div>
          </div>
        </ng-scrollbar>
      </div>
      <div *ngIf="(loadingTextSummary$ | async) || insight" class="z-fx z-fx-none text-summary">
        <div class="z-fx-col z-fx-gap-16 text-summary-area">
          <div *ngIf="loadingTextSummary$ | async" class="z-fx z-fx-start-center z-fx-gap-8 insight">
            <mat-icon>refresh_ai</mat-icon>
            <div class="zef-text-p1 summary-loading" i18n>Generating AI insights...</div>
          </div>
          <div
            *ngIf="insight && (loadingTextSummary$ | async) === false"
            class="z-fx z-fx-start-start z-fx-gap-8 insight"
          >
            <div class="z-fx z-fx-start-start z-fx-gap-8 insight-section">
              <div class="z-fx-col z-fx-start-center z-fx-gap-8 side-stuff">
                <mat-icon>highlight_ai</mat-icon>
                <div class="reload-insights">
                  <mat-icon class="retry-icon zef-icon-micro" (click)="updateTextSummary(true)">retry</mat-icon>
                </div>
              </div>
              <div class="zef-text-p1 summary-text" [class.shortened]="!showOversizedInsight">
                {{ insight }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #nothing>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
</ng-template>
<ng-template #anonymous>
  <div class="z-fx-col z-fx-auto anonymous">
    <div class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number" i18n>under {{ anonymityTreshold }}</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
      <div class="z-fx-col z-fx-end-center image">
        <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
      </div>
      <div class="description zef-text-center">
        <ng-container i18n>
          Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
