/**
 * Contacts export and download dialog.
 *
 * @unstable
 */

import { isEmail } from 'class-validator';

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, first, map, takeUntil } from 'rxjs/operators';

import { environment } from '@env/environment';

import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';

import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';

import { rotateAnimation } from '@shared/animations/rotate.anim';

import { Rights } from '@shared/enums/rights.enum';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';

import { ReportData, SurveyModel } from '@shared/models/survey.model';

import { FeedbackSurvey, FeedbackTrigger } from '@shared/modules/feedback/feedback.models';
import { TriggerFeedback, TriggerFeedbackSurvey } from '@shared/modules/feedback/feedback.actions';

import { SurveyState } from '@shared/states/survey.state';
import { AccountState } from '@shared/states/account.state';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { ReportManager } from '@shared/services/report-manager.service';
import { ReportSave } from '@report/shared/services/report-save.service';

@Component({
  selector: 'report-share',
  templateUrl: './report-share.dialog.html',
  styleUrls: ['./report-share.dialog.scss'],
  providers: [LifecycleHooks],
  animations: [rotateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportShare implements OnInit, OnDestroy {
  readonly rights = Rights;
  readonly subjects = HelpSubject;
  public selectedReport: string = '';
  public selectedSurvey: string = '';
  public selectedReportData: ReportData | null = null;
  public reportName: string = '';
  public onProgress: boolean = false;
  public sendingOnProgress: boolean = false;
  public deletingOnProgress: boolean = false;
  public onProgressText: string = $localize`:@@zef-i18n-00267:On progress...`;
  iconTooltip = $localize`:tooltip@@zef-i18n-00270:Remove rights to report`;
  newEmailPlaceholder = $localize`:input placeholder@@zef-i18n-00272:Enter emails...`;
  addNotePlaceholder = $localize`:input placeholder@@zef-i18n-00273:Message...`;

  public online: boolean = false;
  private setOfflineByUser: boolean = false;
  public publicLink: string = '';
  public showPublicLinkContainer: boolean = false;

  public live: boolean = true;
  public safeReport: boolean = false;
  public extraRespondentFieldAnonymity: boolean = false;
  public ERFAAccordionOpen: boolean = false;
  public previewLink: string = '';
  public newEmails: string[] = [];
  public emailInput: string = '';
  public message: string = '';
  public showUsers: boolean = false;
  public sharedEmails: any[] = [];
  public croppedDates: string[] = [];
  public usersToBeRemoved: any[] = [];
  public anonymityTreshold: number = 0;

  private routerEventsSub: any;
  private sharedEmailsSub: any;
  private listReportsSub: any;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, SEMICOLON];

  @Select(SurveyState.activeSurvey())
  readonly survey$: Observable<SurveyModel>;

  readonly showBetaFeatures$: Observable<boolean> = combineLatest([
    this.store.select(AccountState.disableBetaFeatures),
    this.store.select(AccountState.isChild),
    this.store.select(AccountState.childBetaFeatures),
  ]).pipe(
    map(([disableBetaFeatures, isChild, childBetaFeatures]) => !disableBetaFeatures && (!isChild || childBetaFeatures)),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: any,
    readonly store: Store,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly hooks: LifecycleHooks,
    readonly cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ReportShare>,
    private rs: ReportSave,
    private rm: ReportManager,
  ) {
    this.routerEventsSub = this.router.events
      .pipe(
        takeUntil(this.hooks.destroy),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .subscribe((event) => {
        event.params.pipe(first()).subscribe((params) => {
          this.selectedSurvey = params['survey'];
          this.selectedReport = params['report'] || '';
          this.previewLink =
            'https:' + environment.webAddress + '/preview/' + this.selectedSurvey + '/' + this.selectedReport;
          this.cdRef.detectChanges();
          this.updateSelectedReportData();
        });
      });
  }

  ngOnInit() {
    if (this.data.selectedReportData.title) {
      this.reportName = this.data.selectedReportData.title;
    }
    if (this.data.selectedSurvey) {
      this.selectedSurvey = this.data.selectedSurvey;
    }
    if (this.data.selectedReport) {
      this.selectedReport = this.data.selectedReport;
      this.previewLink =
        'https:' + environment.webAddress + '/preview/' + this.selectedSurvey + '/' + this.selectedReport;
      this.updateSelectedReportData();
    }
    if (this.data.cf) {
      this.safeReport = this.data.cf.getSafeReportStatus() || !!this.data.cf.getAnonymityTreshold();
      this.anonymityTreshold = this.data.cf.getAnonymityTreshold();
      this.extraRespondentFieldAnonymity = this.data.cf.getExtraRespondentFieldAnonymityStatus();
      this.ERFAAccordionOpen = this.extraRespondentFieldAnonymity;
    }
    if (this.data.manageUsers) {
      this.showUsers = true;
    }
  }

  ngOnDestroy() {
    this.usersToBeRemoved = [];
    this.routerEventsSub.unsubscribe();

    if (this.sharedEmailsSub) {
      this.sharedEmailsSub.unsubscribe();
    }

    if (this.listReportsSub) {
      this.listReportsSub.unsubscribe();
    }
  }

  trackByFn(index, item) {
    return item?.email;
  }

  updateSelectedReportData() {
    if (this.listReportsSub) {
      this.listReportsSub.unsubscribe();
    }

    this.listReportsSub = this.rm.listReports(this.selectedSurvey).subscribe((list) => {
      this.selectedReportData = list.find((item) => item.$key === this.selectedReport);
      if (this.selectedReportData && this.selectedReportData.title) {
        this.reportName = this.selectedReportData.title;
      }
      if (
        this.selectedReportData &&
        this.selectedReportData.publicKey &&
        this.selectedReportData.online &&
        !this.setOfflineByUser
      ) {
        this.publicLink = 'https:' + environment.publicUrl + '/r/' + this.selectedReportData.publicKey;
        this.online = true;
        this.showPublicLinkContainer = true;
      }
      if (
        this.selectedReportData &&
        this.selectedReportData.publicKey &&
        !this.selectedReportData.online &&
        !this.onProgress
      ) {
        this.online = false;
      }

      if (this.sharedEmailsSub) {
        this.sharedEmailsSub.unsubscribe();
      }

      this.sharedEmailsSub = this.rm.listReportShares(this.selectedSurvey, this.selectedReport).subscribe((users) => {
        this.sharedEmails = users.filter((user) => user.rights > Rights.NONE);
        this.cdRef.detectChanges();
      });

      this.checkForCropFilters();
      this.cdRef.detectChanges();
    });
  }

  checkForCropFilters() {
    if (this.data.cf.getAnswersParameters()?.filters) {
      const filterObj: { id: string; values: string[] } = this.data.cf
        .getAnswersParameters()
        ['filters'].find((item) => item.id === 'time');

      if (filterObj && filterObj.values && filterObj.values.length > 0) {
        const noStartDate = Date.parse(filterObj.values[0].split('/')[0]);
        const startDate: Date = new Date(filterObj.values[0].split('/')[1]);
        const endDate: Date = new Date(filterObj.values[filterObj.values.length - 1].split('/')[1]);

        const start: string = `${startDate.getUTCDate()}.${startDate.getUTCMonth() + 1} ${startDate.getFullYear()}`;
        const end: string = `${endDate.getUTCDate()}.${endDate.getUTCMonth() + 1} ${endDate.getFullYear()}`;

        this.croppedDates = noStartDate > 0 ? [start, end] : [end];
        this.live = false;
      }
    }
  }

  publishReport() {
    this.online = true;
    this.onOnlineChange({ checked: true });
    this.showPublicLinkContainer = true;
  }

  onOnlineChange($event) {
    if ($event.checked) {
      this.publicLink = this.onProgressText;
      this.onProgress = true;

      this.rs
        .save(this.data.cf, this.data.cm, this.selectedSurvey, this.selectedReport, this.reportName, true, false, 1, {
          explorable: true,
          live: this.live,
        })
        .pipe(takeUntil(this.hooks.destroy))
        .subscribe((report) => {
          this.publicLink = 'https:' + environment.publicUrl + '/r/' + report.publicKey;
          this.onProgress = false;
          this.cdRef.detectChanges();
        });
      this.setOfflineByUser = false;
    } else if (!$event.checked) {
      this.rs
        .save(this.data.cf, this.data.cm, this.selectedSurvey, this.selectedReport, this.reportName, false, true, 1, {
          explorable: true,
          live: this.live,
        })
        .pipe(takeUntil(this.hooks.destroy))
        .subscribe(() => {});
      this.setOfflineByUser = true;
      this.cdRef.detectChanges();
    }
    this.cdRef.detectChanges();
  }

  public validateEmail(email: string) {
    return isEmail(email);
  }

  public validNewEmails() {
    return this.newEmails
      .filter((email) => isEmail(email))
      .filter((email) => this.sharedEmails.map((item) => item.email).indexOf(email) < 0);
  }

  public sendInvitations() {
    this.sendingOnProgress = true;
    this.emailInput = '';
    this.cdRef.detectChanges();

    if (this.validNewEmails().length > 0) {
      this.rm
        .shareReport(this.selectedSurvey, this.selectedReport, this.validNewEmails(), this.message)
        .subscribe((response: any) => {
          this.sendingOnProgress = false;
          this.newEmails = [];
          this.triggerFeedbackSurvey();
          this.store.dispatch(new TriggerFeedback(FeedbackTrigger.ReportSharedViaEmail));
          this.dialogRef.close(response?.users?.length);
        });
    } else {
      this.sendingOnProgress = false;
      this.newEmails = [];
      this.dialogRef.close(0);
    }
  }

  public publicLinkCopied(): void {
    this.triggerFeedbackSurvey();
    this.dialogRef.close();
  }

  public triggerFeedbackSurvey(): void {
    this.store.dispatch(new TriggerFeedbackSurvey(FeedbackSurvey.ShareReportSurvey));
  }

  public toggleUsers() {
    this.usersToBeRemoved = [];
    this.showUsers = !this.showUsers;
    this.cdRef.detectChanges();
  }

  public removeUser(user) {
    const existingIndex: number = this.usersToBeRemoved.findIndex((removedUser) => user.email === removedUser.email);
    if (existingIndex < 0) {
      this.usersToBeRemoved.push(user);
    } else {
      this.usersToBeRemoved.splice(existingIndex, 1);
    }
    this.cdRef.detectChanges();
  }

  public removeUsers() {
    this.deletingOnProgress = true;
    this.rm.unshareReports(this.selectedSurvey, this.selectedReport, this.usersToBeRemoved).subscribe(() => {
      this.usersToBeRemoved = [];
      this.deletingOnProgress = false;
      this.cdRef.detectChanges();
    });
  }

  public isUserRemoved(user) {
    return !!this.usersToBeRemoved.find((removedUser) => user.email === removedUser.email);
  }

  public cancelPrivateShare() {
    this.newEmails = [];
    this.emailInput = '';
  }

  public paste($event: ClipboardEvent): void {
    if (
      $event &&
      $event.clipboardData &&
      $event.clipboardData.getData('Text') &&
      $event.clipboardData.getData('Text').split(/;|,| |\n|\t/).length > 1
    ) {
      $event.preventDefault();
      $event.clipboardData
        .getData('Text')
        .split(/;|,| |\n|\t/)
        .forEach((value) => {
          const trimmedValue = (value || '').trim();

          if (trimmedValue && this.newEmails.indexOf(trimmedValue) < 0) {
            this.newEmails.push(value.trim());
          }
        });
    }
  }

  public add(event: MatChipInputEvent): void {
    const value = event.value;
    const trimmedValue = (value || '').trim();

    // Add email address
    if (trimmedValue && this.newEmails.indexOf(trimmedValue) < 0) {
      this.newEmails.push(value.trim());
    }

    // Reset the input value
    this.emailInput = '';
  }

  public removeChip(email: string): void {
    const index = this.newEmails.indexOf(email);

    if (index >= 0) {
      this.newEmails.splice(index, 1);
    }

    this.emailInput = '';
  }

  uncropData() {
    this.data.cf.removeCropFilters(['time']);
    this.live = true;
    this.croppedDates = [];
    this.cdRef.detectChanges();
  }

  onLiveChange($event) {
    if (this.croppedDates.length > 1) {
      setTimeout(() => {
        this.live = false;
        this.cdRef.detectChanges();
      }, 100);
    } else if ($event.checked === false && this.croppedDates.length === 0) {
      const filterDate: string = `${new Date(0).toISOString()}/${new Date().toISOString()}`;
      this.data.cf.addCropFilter({ id: 'time', values: [filterDate] });
      this.croppedDates = [`${new Date().getUTCDate()}.${new Date().getUTCMonth() + 1} ${new Date().getFullYear()}`];
      this.cdRef.detectChanges();
    } else if ($event.checked === true && this.croppedDates.length === 1) {
      this.data.cf.removeCropFilters(['time']);
      this.croppedDates = [];
      this.cdRef.detectChanges();
    }
  }

  onSafeReportChange($event) {
    this.data.cf.toggleSafeReport($event.checked);
    this.rs
      .quickSave()
      .pipe(catchError(() => of(null)))
      .subscribe(() => {});
  }

  onExtraRespondentFieldAnonymityChange($event) {
    this.data.cf.toggleExtraRespondentFieldAnonymity($event.checked);
    this.rs
      .quickSave()
      .pipe(catchError(() => of(null)))
      .subscribe(() => {});
  }
}
