import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { CanvasContext, ChartDomain, ChartLegend } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';
import { drawRoundRect, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Topic Bubble chart directive.
 */
@Directive({
  selector: '[topicBubble]',
})
export class TopicBubbleChart implements OnChanges {
  @Input() chartData: any = [];
  @Input() scale: [min: number, max: number] = [0, 100];
  @Input() axisLabels: string[] = [];

  @Input() size: number = 0;
  @Input() highlight: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() zvalues: boolean = false;
  @Input() showAverages: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() showZoomText: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() comparisonMode: string = 'grouped';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() showOnlyGroupAverages: boolean = false;
  @Output() hover: EventEmitter<string[]> = new EventEmitter<string[]>();

  private margin: any = { top: 32, right: 32, bottom: 32, left: 32 };
  private width: number = 0;
  private height: number = 0;
  private ballSizeMax: number = 0;
  private ballSizeMin: number = 0;
  private fontSize: number = 0;

  private context: CanvasContext = {} as CanvasContext;
  private legends: ChartLegend[] = [];

  // D3 elements
  private base: any;
  private canvas: any;
  private tooltip: any;
  private tooltipTexts: any;
  private scaleX: any;
  private scaleY: any;
  private scaleBallSize: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null, null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.chartData ||
      changes.size ||
      changes.highlight ||
      changes.domain ||
      changes.zvalues ||
      changes.showAverages ||
      changes.update ||
      changes.comparison ||
      changes.comparisonMode ||
      changes.filtersDemo ||
      changes.selectedItems ||
      changes.showOnlyGroupAverages
    ) {
      this.updateChart(changes.update, changes.zvalues);
    }
  }

  constructBody() {
    this.margin = { top: 32, right: 32, bottom: 32, left: 32 };
    this.base = d3.select(this._element.nativeElement);
  }

  updateChart(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    this.setSizes();
    this.setScales();
    this.setCanvas(dataChanges, zChanges);
  }

  /**
   * Setting sizes.
   */
  setSizes() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.width =
      this._element.nativeElement.clientWidth - this.margin.left - this.margin.right > 0
        ? this._element.nativeElement.clientWidth - this.margin.left - this.margin.right
        : 0;
    this.height =
      this._element.nativeElement.clientHeight - this.margin.bottom - this.margin.top > 0
        ? this._element.nativeElement.clientHeight - this.margin.bottom - this.margin.top
        : 0;
  }

  /**
   * Setting scales.
   */
  setScales() {
    this.ballSizeMax = Math.min(Math.min(this.width, this.height) / 3 / 2, 64);
    this.ballSizeMin = Math.max(Math.min(this.width, this.height) / 24 / 2, 16);
    const biggest = Math.max(...this.chartData.map((d) => d?.size)) || 1;
    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain(this.scale);
    this.scaleY = d3.scaleLinear().rangeRound([this.height, 0]).domain(this.scale);
    this.scaleBallSize = d3.scaleLinear().rangeRound([this.ballSizeMin, this.ballSizeMax]).domain([0, biggest]);
  }

  /**
   * Selecting colors.
   */
  colorScale(index) {
    if (this.comparison) {
      return Colors.getComparisonColor(index);
    } else {
      return this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    }
  }

  setCanvas(dataChanges: SimpleChange | null, zChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      const dataItems = d || [];

      let averages: number[] = [];

      if (__this.showAverages) {
        averages = [
          __this.zvalues
            ? __this.margin.left + __this.width / 2
            : d3.mean(
                dataItems.filter((c) => !c?.isUnderAnonymityTreshold),
                (c: any) => c.x + __this.margin.left,
              ),
          __this.zvalues
            ? __this.margin.top + __this.height / 2
            : d3.mean(
                dataItems.filter((c) => !c?.isUnderAnonymityTreshold),
                (c: any) => c.y + __this.margin.top,
              ),
        ];
      }

      if (
        ((dataChanges && !dataChanges.firstChange) || (zChanges && !zChanges.firstChange)) &&
        __this.transitionDuration > 0
      ) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const averageObj =
          averages.length === 2 && __this.context && __this.context.averages ? __this.context.averages : [];
        const interpolateBiggest = d3.interpolate(
          Math.max(...dataObj.map((theme) => theme?.size)) || 1,
          Math.max(...dataItems.map((theme) => theme?.size)) || 1,
        );
        const interpolator = d3.interpolateArray(dataObj, dataItems);
        const averageInterpolator = d3.interpolateArray(averageObj, averages);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let average;
          let scale;

          if (step >= 1) {
            data = dataItems;
            average = __this.showAverages ? averages : [];
            scale = __this.scaleBallSize;

            t.stop();
          } else {
            data = interpolator(ease(step));
            average = averageInterpolator(ease(step));
            scale = d3
              .scaleLinear()
              .rangeRound([__this.ballSizeMin, __this.ballSizeMax])
              .domain([0, interpolateBiggest(ease(step))]);
          }
          __this.setChart(context, data, scale, [], average);
        });
      } else {
        __this.setChart(context, dataItems, __this.scaleBallSize, [], averages);
      }

      __this.context = { context, data: dataItems, averages };
    };

    const hoverFunction = function (event) {
      const area = d3.pointer(event);
      __this.selectForHover(event, area);
    };

    this.canvas = this.base.selectAll('.topic-bubble-canvas').data([this.chartData]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'topic-bubble-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event) {
        __this.setTooltip(event, d3.pointer(event));
      })
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setChart(context, dataOrig, scaleBallSize, highlightedInChart: string[] = [], averages: number[] = [], event = null) {
    const data = dataOrig.slice().sort((a, b) => a.size - b.size);
    const highlighted = highlightedInChart.length > 0 ? highlightedInChart : this.highlight;

    context.clearRect(
      0,
      0,
      this.width + this.margin.top + this.margin.bottom,
      this.height + this.margin.left + this.margin.right,
    );
    context.globalAlpha = 1;

    this.setArea(context);
    this.setTexts(context);
    if (this.comparison && this.comparisonMode === 'joined') {
      this.setLegends(context);
    }

    const highlightsOn = highlighted && highlighted.length > 0;
    const highlightArea: number[][] = [];

    for (let i = 0, len = data.length; i < len; i++) {
      if (data[i]?.size && !data[i]?.isUnderAnonymityTreshold) {
        const x = this.scaleX(data[i].x);
        const y = this.scaleY(data[i].y);
        const ballSize = scaleBallSize(data[i].size);
        let highlight;
        if (highlightsOn) {
          highlight = highlighted.find((item) => {
            const legends =
              (!this.highlight || this.highlight.length === 0) && event != null
                ? this.itemsBelow(d3.pointer(event, this._element.nativeElement.firstElementChild)).legends
                : [];

            return (
              data[i].title === item &&
              (legends.length === 0 || legends.find((legend) => data[i].group === legend.key) != null)
            );
          });

          if (highlight) {
            context.globalAlpha = 0.8;
            highlightArea.push([x, y]);
          } else {
            const onTop = highlightArea.filter(
              (area) =>
                area[0] < x + ballSize && area[0] > x - ballSize && area[1] < y + ballSize && area[1] > y - ballSize,
            );
            if (onTop.length > 0 || (!!this.selectedItems?.length && !this.selectedItems.includes(data[i]?.title))) {
              context.globalAlpha = 0.25;
            } else if (!!this.selectedItems?.length && this.selectedItems.includes(data[i]?.title)) {
              context.globalAlpha = 1;
            } else {
              context.globalAlpha = !this.highlight?.length ? 0.5 : 0.25;
            }
          }
        } else if (!!this.selectedItems?.length && !this.selectedItems.includes(data[i]?.title)) {
          context.globalAlpha = 0.25;
        } else if (!!this.selectedItems?.length && this.selectedItems.includes(data[i]?.title)) {
          context.globalAlpha = 1;
        } else {
          context.globalAlpha = 0.5;
        }

        this.setBalls(
          context,
          data[i],
          scaleBallSize,
          highlight,
          (highlightedInChart?.length && highlight) ||
            (!!this.selectedItems?.length && this.selectedItems.includes(data[i]?.title)),
        );
      }
    }

    if (averages && averages.length === 2 && data.filter((d) => !d?.isUnderAnonymityTreshold)?.length > 0) {
      context.fillStyle = Colors.AVERAGEHELPER;
      context.beginPath();
      context.arc(averages[0], averages[1], 5, 0, 2 * Math.PI, false);
      context.closePath();
      context.fill();
    }
  }

  setArea(context) {
    const areaW = this.width;
    const areaH = this.height;

    context.fillStyle = Colors.WHYFINDERBACKGROUND;
    drawRoundRect(
      context,
      0,
      0,
      this.width + this.margin.left + this.margin.right,
      this.height + this.margin.top + this.margin.bottom,
      8,
      true,
      false,
    );
    context.strokeStyle = Colors.BACKGROUND;
    context.lineWidth = 4;

    context.translate(this.margin.left, this.margin.top);
    context.beginPath();
    context.moveTo(areaW / 2, 0);
    context.lineTo(16, 0);
    context.translate(16, 16);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 16, -1.5707963267948966, -3.141592653589793, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-16, -16);
    context.lineTo(0, areaH / 2);
    context.lineTo(32 * (areaW / 100), areaH / 2);
    context.lineTo(0, areaH / 2);
    context.lineTo(0, areaH - 16);
    context.translate(16, areaH - 16);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 16, 3.141592653589793, 1.5707963267948966, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-16, -(areaH - 16));
    context.lineTo(areaW / 2, areaH);
    context.lineTo(areaW / 2, 68 * (areaH / 100));
    context.lineTo(areaW / 2, areaH);
    context.lineTo(areaW - 16, areaH);
    context.translate(areaW - 16, areaH - 16);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 16, 1.5707963267948966, 0, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-(areaW - 16), -(areaH - 16));
    context.lineTo(areaW, areaH / 2);
    context.lineTo(68 * (areaW / 100), areaH / 2);
    context.lineTo(areaW, areaH / 2);
    context.lineTo(areaW, 16);
    context.translate(areaW - 16, 16);
    context.rotate(0);
    context.scale(1, 1);
    context.arc(0, 0, 16, 0, -1.5707963267948966, 1);
    context.scale(1, 1);
    context.rotate(0);
    context.translate(-(areaW - 16), -16);
    context.lineTo(areaW / 2, 0);
    context.lineTo(areaW / 2, 32 * (areaH / 100));
    context.closePath();
    context.stroke();
    context.translate(-this.margin.left, -this.margin.top);
  }

  setLegends(context) {
    this.legends = [];
    const y = 0;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 15;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    let choiceLabels;
    let choices = [];
    let colors;

    for (const dom in this.domain) {
      if (this.domain[dom].key === this.comparison.key) {
        choiceLabels = this.domain[dom].labels;
        choices = this.domain[dom].keys;
        colors = this.domain[dom].colors;
      }
    }

    const textWidth = (width * (choices.length > 2 ? 3 : choices.length < 2 ? 1 : 2)) / choices.length;

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = choices.length; i < length; i++) {
      const key = choices[i];
      const text = shortenText(context, choiceLabels[key], textWidth, 40);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 20 <= width) {
        usedSpace += elW + 20;
        arr.push([key, colors[key] != null ? colors[key] : i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += 10;

          context.font = 10 / 14 + 'em Inter';
          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 8, currentY + y + 6, 5, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(itemColor);
          context.fill();
          this.legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
          });
          currentX += context.measureText(itemText).width + 10;
        }

        currentY += height;
        usedSpace = elW + 20;

        arr = [[key, colors[key] != null ? colors[key] : i, text]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      currentX += 10;

      context.font = 10 / 14 + 'em Inter';
      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 8, currentY + y + 6, 5, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(itemColor);
      context.fill();
      this.legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
      });
      currentX += context.measureText(itemText).width + 10;
    }
  }

  setTexts(context) {
    context.font = 12 / 14 + 'em Inter';
    context.textBaseline = 'middle';
    context.fillStyle = Colors.WHYFINDERHELPERTEXT;
    context.strokeStyle = Colors.BACKGROUND;
    context.lineWidth = 8;

    context.font = 'bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const xAxisLabel = shortenText(context, this.axisLabels[0], this.width, 10);
    context.strokeText(xAxisLabel, this.margin.left + this.width / 2, this.height + this.margin.top);
    context.font = 'normal ' + 10 / 14 + 'em Inter';
    context.fillText(xAxisLabel, this.margin.left + this.width / 2, this.height + this.margin.top);

    context.rotate((270 * Math.PI) / 180);

    context.font = 'bold ' + 12 / 14 + 'em Inter';
    context.textAlign = 'center';
    const yAxisLabel = shortenText(context, this.axisLabels[1], this.width, 10);
    context.strokeText(yAxisLabel, 0 - (this.margin.top + this.height / 2), this.margin.left);
    context.font = 'normal ' + 10 / 14 + 'em Inter';
    context.fillText(yAxisLabel, 0 - (this.margin.top + this.height / 2), this.margin.left);

    context.rotate((90 * Math.PI) / 180);
  }

  setAverageLines(context, data, meanX, meanY) {
    context.save();
    context.setLineDash([5, 10]);
    context.strokeStyle = '#a4b0b9';
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(meanX, meanY);
    context.lineTo(data.x + this.margin.left, data.y + this.margin.top);
    context.stroke();
    context.restore();
  }

  setBalls(context, data, scaleBallSize, highlight, stroke = false) {
    context.fillStyle = data.color || this.colorScale(data.index);
    context.strokeStyle = Colors.HIGHLIGHT;
    context.lineWidth = !(this.selectedItems?.length && this.selectedItems.includes(data.title)) ? 1 : 2;

    const x = this.scaleX(data.x) + this.margin.left;
    const y = this.scaleY(data.y) + this.margin.top;
    const radius = scaleBallSize(data.size);

    // if (highlight || (!!this.selectedItems?.length && this.selectedItems.includes(data.title))) {
    //   context.beginPath();
    //   context.arc(x, this.margin.top + this.height, 3, 0, 2 * Math.PI, false);
    //   context.moveTo(x, this.margin.top + this.height - 3);
    //   context.lineTo(x, y + radius);
    //   context.moveTo(x - radius, y);
    //   context.lineTo(this.margin.left + 3, y);
    //   context.arc(this.margin.left, y, 3, 0, 2 * Math.PI, false);

    //   context.stroke();
    // }

    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI, false);
    context.closePath();

    context.fill();
    if (stroke) {
      context.stroke();
    }

    context.globalAlpha = 1;
    context.fillStyle = data.colorText || this.colorScale(data.index);
    context.strokeStyle = Colors.BACKGROUND;
    context.font = '500 ' + 10 / 14 + 'em Inter';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.lineWidth = 2;

    if (data.title != null) {
      const yFixed = Math.min(
        y + radius + this.fontSize,
        this.height + this.margin.top + this.margin.bottom - this.fontSize / 2,
      );
      context.strokeText(data.title.toUpperCase(), x, yFixed);
      context.fillText(data.title.toUpperCase(), x, yFixed);
    }
  }

  setTooltip(event, position, data: any[] = []) {
    this.hover.emit(data.map((item) => item.title));
    this.setHoverEffects(event, this.context, data);
  }

  setHoverEffects(event, context, data) {
    this.setChart(
      this.context.context,
      this.context.data,
      this.scaleBallSize,
      data.map((item) => item.title),
      null,
      event,
    );

    if (data.length > 0) {
      this.base.select('.topic-bubble-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.topic-bubble-canvas').style('cursor', null);
    }
  }

  // Helpers
  selectForHover(event, area) {
    const itemsBelow = this.itemsBelow(area);
    const parents = itemsBelow.parents;
    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(event, d3.pointer(event, this._element.nativeElement), parents);
    } else if (legends.length > 0) {
      const legendItems = this.chartData.filter((item) =>
        // (this.showOnlyGroupAverages ? !item.questionGroup || item.isGroupAverage : !item.isGroupAverage) &&
        legends.find((legend) => legend.key.toString() === item.group),
      );

      this.setTooltip(event, d3.pointer(event, this._element.nativeElement), legendItems);
    } else {
      this.setTooltip(event, d3.pointer(event, this._element.nativeElement));
    }
  }

  itemsBelow(area) {
    const parents = this.chartData.filter((item) => {
      const x = this.scaleX(item.x);
      const y = this.scaleY(item.y);
      const ballSize = this.scaleBallSize(item.size);
      return (
        !item.isUnderAnonymityTreshold &&
        item?.size &&
        (this.showOnlyGroupAverages ? !item.questionGroup || item.isGroupAverage : !item.isGroupAverage) &&
        area[0] < x + ballSize + this.margin.left &&
        area[0] > x - ballSize + this.margin.left &&
        area[1] < (y ? y : 0) + ballSize + this.margin.top &&
        area[1] > (y ? y : 0) - ballSize + this.margin.top
      );
    });

    const legends =
      this.legends && this.legends.length > 0
        ? this.legends.filter(
            (item) =>
              area[0] > item.x - 15 &&
              area[0] < item.x + item.width &&
              area[1] > item.y &&
              area[1] < item.y + item.height,
          )
        : [];

    return { parents, legends };
  }
}
