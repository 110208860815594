import { StateToken } from '@ngxs/store';
import { environment } from '@env/environment';

export const FEEDBACK_DELAY = environment.production ? 7.776e9 : 90000;

export enum FeedbackTrigger {
  SurveyPublished = 'survey-published',
  SurveyInvitationSent = 'survey-invitation-sent',
  ReportSharedViaEmail = 'report-shared-via-email',
}

export const enum FeedbackSurvey {
  AnswerSurvey = 1,
  ReportSurvey,
  ShareReportSurvey,
  GeneralZeffiSurvey,
}

// production, staging, testing
export const FeedbackSurveyKeys: Record<FeedbackSurvey, string> = {
  [FeedbackSurvey.AnswerSurvey]: environment.production ? (!environment.debug ? 'smabg6nn' : 'ccermw2a') : 'fdnnyafi',
  [FeedbackSurvey.ReportSurvey]: environment.production ? (!environment.debug ? 'q4pbfqwj' : '24ftlvge') : 'akk8w2zh',
  [FeedbackSurvey.ShareReportSurvey]: environment.production
    ? !environment.debug
      ? 'nniihzze'
      : 'wlbqtn98'
    : '9hvmg4om',
  [FeedbackSurvey.GeneralZeffiSurvey]: environment.production
    ? !environment.debug
      ? 'ka4w4aqx'
      : 'w4wvoloa'
    : '062gzinh',
};

export interface FeedbackSurveyStatus {
  popupTime: number;
}

export interface FeedbackStateModel {
  surveys: Record<FeedbackSurvey, FeedbackSurveyStatus>;
}

export const FEEDBACK_STATE = new StateToken<FeedbackStateModel>('feedback');
