import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, filter, take, tap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import {
  ChartDistribution,
  ChartDomain,
  ChartStats,
  DimensionDataItem,
  SummaryAverageData,
  SummaryAverageDataChild,
} from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';
import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { ReportAssistant } from '@report/shared/services/report-assistant.service';

/**
 * This is a Summary averages chart component.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'summary-averages',
  templateUrl: './summary-averages.component.html',
  styleUrls: ['./summary-averages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryAverages implements OnChanges, OnInit {
  @Input() distribution: ChartDistribution[] = [];
  @Input() stats: ChartStats[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() anonymityTreshold: number = null;
  @Input() chartKey: string = '';
  @Input() isSharedReport: boolean = false;

  public chartData: SummaryAverageData[] = [];
  public loadingTextSummary$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private textSummaryUpdateQueue$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public insight: string = '';
  public hasNoAverages: boolean = false;
  public hasNoAveragesAnonymity: boolean = false;
  public showAlwaysDecimals: boolean = false;

  private axisGroups: any;
  private baseSituationArgs: [
    language: string,
    averageNumber: string,
    questionCount: string,
    sliderScale: string,
    questionData: string,
    groupData: string,
    customInstructions: string,
  ];
  private baseSituationInsight: string = '';

  @HostListener('window:resize') resize() {
    this.updateChart();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    private ra: ReportAssistant,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.distribution ||
      changes.stats ||
      changes.domain ||
      changes.details ||
      changes.filterInput ||
      changes.update ||
      changes.comparison ||
      changes.chartKey ||
      changes.anonymityTreshold ||
      changes.isSharedReport
    ) {
      this.updateChart();
    }
  }

  ngOnInit() {
    this.textSummaryUpdateQueue$
      .pipe(
        takeUntil(this.hooks.destroy),
        debounceTime(300),
        switchMap(() =>
          this.loadingTextSummary$.pipe(
            filter((loading) => !loading),
            take(1),
          ),
        ),
        tap(() => this.updateTextSummary()),
      )
      .subscribe();
  }

  updateChart() {
    this.setQuestions();
    this.setData();
    this.textSummaryUpdateQueue$.next('');
  }

  /**
   * Parsing questions from data by connecting dimension pairs together.
   */
  setQuestions() {
    this.axisGroups = {};

    // const naturalPairs = {};
    for (let i = 0; i < this.details.length; i++) {
      const item = i.toString();
      const question = [item];
      if (
        this.details[item].originalType === Questions.SLIDER_1D ||
        this.details[item].originalType === Questions.SLIDER_1V ||
        this.details[item].originalType === Questions.SLIDER_NPS ||
        this.details[item].originalType === Questions.SLIDER_E_NPS ||
        this.details[item].originalType === Questions.SLIDER_2D ||
        this.details[item].originalType === Questions.SLIDER_1R ||
        this.details[item].originalType === Questions.INPUT_NUMERIC ||
        this.details[item].originalTypeSpecifier === 'text-sentiment' ||
        this.details[item].scale === 'linear'
      ) {
        const group = this.details[item].group;
        const groupId = this.parseGroupId(this.details[item]);

        if (!this.axisGroups[groupId]) {
          this.axisGroups[groupId] = { groups: new Set(), questions: [] };
        }

        question.push(group, groupId);

        this.axisGroups[groupId]['groups'].add(group);
        this.axisGroups[groupId]['questions'].push(question);
      }
    }
  }

  /**
   * Setting data for averages.
   */
  setData() {
    const chartData: SummaryAverageData[] = [];
    let totalN = 0;
    const rawDataTable = this.cf.getTextAnswersFor(this.details, this.isSharedReport, true);

    const setData = (comparisonGroup = '', comparisonItem = '', cidx = 0) => {
      const children: SummaryAverageDataChild[] = [];
      const questions = new Set();
      const id = comparisonItem ? comparisonItem : 'aggregate';
      const sliderDetailsArr = [];
      let group;
      const comparisonDomain = this.domain.find((dom) => dom.key === comparisonGroup);
      const cdi = this.domain.findIndex((dom) => dom.key === comparisonGroup);

      let number = 0;

      for (let x = 0, len = this.domain.length; x < len; x++) {
        if (this.domain[x] && this.details[x] && this.domain[x].scale === 'linear') {
          const sliderLabels = {
            axis: this.details[x].labelsLinear?.axis,
            min: this.details[x].labelsLinear?.min,
            max: this.details[x].labelsLinear?.max,
          };
          const sliderValues = this.details[x].valueScaleLinear;
          const zMethod = Object.keys(this.axisGroups).length > 1 ? 'globalZ' : 'z';
          const key = this.details[x].key;
          const domIndex = this.domain[x].index;
          const title = this.domain[x].title ? this.domain[x].title : this.details[x].title || '';
          const questionGroup = this.details[x].group;
          const questionGroupLabel = this.domain[x].groupLabel;

          const n = comparisonDomain
            ? this.stats[cdi] &&
              this.stats[cdi]['children'] &&
              this.stats[cdi]['children'][cidx] &&
              this.stats[cdi]['children'][cidx]['children'] &&
              this.stats[cdi]['children'][cidx]['children'][domIndex] &&
              this.stats[cdi]['children'][cidx]['children'][domIndex]['count'] != null
              ? this.stats[cdi]['children'][cidx]['children'][domIndex]['count']
              : null
            : this.stats[x] && this.stats[x]['count'];
          const isUnderAnonymityTreshold: boolean = this.anonymityTreshold && this.anonymityTreshold > n;
          totalN += n;

          const average = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex]['average'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex]['average']
                : null
              : this.stats[x] && this.stats[x]['average']
            : null;
          const std = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex]['std'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex]['std']
                : null
              : this.stats[x] && this.stats[x]['std']
            : null;
          const standardizedAverage = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`]
                : null
              : this.stats[x] && this.stats[x][`${zMethod}Average`]
            : null;
          const standardizedStd = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`]
                : null
              : this.stats[x] && this.stats[x][`${zMethod}Std`]
            : null;

          sliderDetailsArr.push({
            labels: sliderLabels,
            values: sliderValues,
          });
          if (!group) {
            group = {
              key: comparisonItem ? comparisonItem : 'aggregate',
              title: comparisonItem
                ? `${comparisonDomain ? comparisonDomain['labels'][comparisonItem] : ''}`
                : $localize`Overall`,
              index: comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
              count: !comparisonItem
                ? rawDataTable.length
                : rawDataTable.filter((d) => d?.[cdi + 1]?.value?.[0]?.includes(cidx)).length,
            };
          }

          number += 1;

          if (n >= 0) {
            const child: SummaryAverageDataChild = {
              key,
              title,
              n: !isUnderAnonymityTreshold ? n : null,
              average,
              std,
              standardizedAverage,
              standardizedStd,
              questionGroup,
              questionGroupLabel,
            };
            children.push(child);
            questions.add(number + '. ' + title);
          }
        }
      }
      const overallAverage =
        Math.round((children.reduce((a, b) => a + b.average * b.n, 0) / children.reduce((a, b) => a + b.n, 0)) * 10) /
        10;
      const sliderDetails: any = sliderDetailsArr.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          sliderDetailsArr.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });

      if (sliderDetails.some((det) => Math.abs(det?.values?.max - det?.values?.min) < 20)) {
        this.showAlwaysDecimals = true;
      }

      return {
        children,
        id,
        sliderDetails,
        group,
        questions: Array.from(questions) as string[],
        overallAverage: !isNaN(overallAverage) ? overallAverage : null,
      };
    };

    chartData.push(setData());

    if (this.comparison && this.comparison.values.length > 0) {
      const comparisonDetails = this.details.find((details) => details.key === this.comparison.key);

      if (comparisonDetails) {
        for (let c = 0, len = this.comparison.values.length; c < len; c++) {
          if (comparisonDetails['values'][this.comparison.values[c]]) {
            const comparisonItem = comparisonDetails['values'][this.comparison.values[c]].toString();
            const comparisonValue = this.comparison.values[c];
            const comparisonData: SummaryAverageData = setData(this.comparison.key, comparisonItem, comparisonValue);

            chartData.push(comparisonData);
          }
        }
      }
    }

    this.chartData = chartData;
    this.hasNoAverages = totalN === 0;
    this.hasNoAveragesAnonymity =
      this.anonymityTreshold && this.chartData[0]?.children?.filter((child) => child.average != null)?.length === 0;
  }

  private getQuestionGroupData(questions: SummaryAverageDataChild[]): string {
    const groupKeys = Array.from(new Set(questions.map((question) => question.questionGroup)));
    const groupData = groupKeys.map((groupKey) => ({
      key: groupKey,
      groupName: questions.find((question) => question.questionGroup === groupKey)?.questionGroupLabel,
      average:
        Math.round(
          (questions
            .filter((question) => question.questionGroup === groupKey)
            .reduce((a, b) => a + b.average * b.n, 0) /
            questions.filter((question) => question.questionGroup === groupKey).reduce((a, b) => a + b.n, 0)) *
            100,
        ) / 100,
      questionIncluded: questions.filter((question) => question.questionGroup === groupKey).length,
    }));

    return JSON.stringify(groupData);
  }

  public updateTextSummary(forceUpdate: boolean = false) {
    this.loadingTextSummary$.next(true);
    const isBaseSituation: boolean = !Object.keys(this.cf?.filters || {}).length;
    const surveyKey = this.cf.key;
    const language: string = this.cf?.getLocaleConfig()?.[this.cf?.getActiveLocale()]?.name || 'English';
    const reportKey: string = this.cf?.connectedReportKey || '';
    const averageNumber: string = this.chartData?.[0]?.overallAverage?.toString() || '';
    const questionCount: string = this.chartData?.[0]?.children?.length?.toString() || '';
    const sliderScale: string = JSON.stringify(this.chartData?.[0]?.sliderDetails) || '';
    const questionData: string = JSON.stringify(this.chartData?.[0]?.children) || '';
    const groupData: string = this.getQuestionGroupData(this.chartData?.[0]?.children);
    const customInstructions: string =
      this.cf.getOverallAverages()?.[this.chartKey?.split(':')?.[1]]?.customAIInstructions || '';
    const properties: [
      language: string,
      averageNumber: string,
      questionCount: string,
      sliderScale: string,
      questionData: string,
      groupData: string,
      customInstructions: string,
    ] = [language, averageNumber, questionCount, sliderScale, questionData, groupData, customInstructions];
    if (isBaseSituation) {
      this.baseSituationArgs = properties;
    }
    let messages: { role: string; message: string }[] = [];

    if (!isBaseSituation) {
      const alteredState: string = `${this.generateFiltersLabel()}
        **Data You Have:**
        - Total average number: **${averageNumber}**
        - Number of questions used to calculate this average: **${questionCount}**
        - Scale used for the questions: **${sliderScale}**
        - Individual questions behind the average: **${questionData}**
        - Grouped data (e.g., data grouped by certain categories or demographics): **${groupData}**
        - Language for the summary: **${language}**`;
      messages = [
        { role: 'assistant', message: this.baseSituationInsight },
        { role: 'user', message: alteredState },
      ];
    }

    const args = (isBaseSituation ? '' : this.generateFiltersLabel) + properties.join('');
    const cachedInsight: string = this.cf.getCachedAIInsight(this.chartKey, args);

    if (!cachedInsight || forceUpdate) {
      this.ra
        .getSummaryAveragesTextSummary(
          surveyKey,
          reportKey,
          isBaseSituation ? properties : this.baseSituationArgs,
          messages,
        )
        .subscribe((insight) => {
          if (insight) {
            this.cf.updateCachedAIInsight(this.chartKey, args, insight);
            if (isBaseSituation) {
              this.baseSituationInsight = insight;
            }
            this.insight = insight;
            this.loadingTextSummary$.next(false);
            this.cdRef.detectChanges();
          }
        });
    } else if (cachedInsight) {
      this.insight = cachedInsight;
      this.loadingTextSummary$.next(false);
      this.cdRef.detectChanges();
    }
  }

  private generateFiltersLabel(): string {
    let labelText: string = `Active filters applied. ${this.cf.getRespondentCounts()?.selected} / ${
      this.cf.getRespondentCounts()?.total
    } respondents selected.\n`;
    let i: number = 0;
    for (const f in this.cf?.filters || {}) {
      const timeFilterValues = (tv, tf) => tf.map((tfv) => this.cf.getTimeFormat(tv)?.format(new Date(Number(tfv))));
      const filter = this.cf.filters[f];
      const isQuestion: boolean = ![
        'time',
        'survey',
        'lang',
        'shareLink',
        'hashtags',
        'zefSurveyUserRating',
        'userSegments',
        'outcomes',
        'respondent-field',
      ].includes(filter?.dimensionData?.originalType);
      const isTimeFilter: boolean = filter?.dimensionData?.originalType === 'time';

      if (i > 0) {
        labelText += ',\n';
      }

      labelText += `${i + 1}. `;
      labelText += isQuestion
        ? 'Question filter: '
        : filter?.dimensionData?.originalType === 'respondent-field'
          ? 'Background information filter: '
          : 'Filter: ';
      labelText += `"${filter?.dimensionData.title}" `;
      labelText += isQuestion ? 'Selected answer(s): ' : 'Selected option(s): ';
      labelText += `"${
        !isTimeFilter ? filter?.['customName'] || filter?.labels : timeFilterValues(filter?.values, filter?.filter)
      }"`;

      i++;
    }
    return labelText;
  }

  public colorScale(index): string {
    if (index != null && this.comparison && this.comparison.values.length > 0) {
      return Colors.getComparisonColor(index);
    } else {
      return Colors.TEXT;
    }
  }

  public hasOnlyAnonymousQuestions(questions): boolean {
    return !questions.some((q) => !q?.isUnderAnonymityTreshold);
  }

  trackByKey(i: number, item: any[]): number {
    return (item && item[0] && item[0]['key']) || null;
  }

  trackById(i: number, item: any[]): number {
    return (item && item['id']) || null;
  }

  /**
   * Parsing group ids from axis details.
   *
   * @param detailsX   Dimension details for axis x.
   * @param detailsY  Dimension details for axis x.
   * @returns        Parsed group id.
   */
  parseGroupId(details: DimensionDataItem): string {
    return `${details?.labelsLinear?.axis || ''}: ${details?.labelsLinear?.min || ''} - ${
      details?.labelsLinear?.max || ''
    } (${details?.valueScaleLinear?.min}-${details?.valueScaleLinear?.max})`;
  }
}
