import {
  FeedbackSettings,
  SignupData,
  TeamAdminSettings,
  TeamData,
  TeamParentSettings,
} from '@shared/models/account.model';
import { Rights } from '@shared/enums/rights.enum';
import { UserItem } from '@shared/models/prefs.model';
import { CONSOLE_ZEFFI_TEAM } from '@admin/shared/states/console-state.models';

export class GetConsoleTeam {
  static type = '[Console] Get Team';

  constructor(readonly teamKey?: string) {}
}

export class GetConsoleTeamPlan {
  static type = '[Console] Get Team Plan';

  constructor(readonly teamKey: string) {}
}

export class ConnectConsoleTeamToStripe {
  static type = '[Console] Connect Team To Stripe';

  constructor(readonly teamKey: string) {}
}

export class UpdateConsoleTeam {
  static type = '[Console] Update Team';

  constructor(
    readonly teamKey: string,
    readonly update: Partial<TeamData>,
  ) {}
}

export class UpdateConsoleTeamAdminSettings {
  static type = '[Console] Update Team Admin Settings';

  constructor(
    readonly teamKey: string,
    readonly update: Partial<TeamAdminSettings>,
  ) {}
}

export class DeleteConsoleTeam {
  static type = '[Console] Delete Team';

  constructor(readonly teamKey: string) {}
}

export class AddSmsToTeam {
  static type = '[Console] Add Sms Team';

  constructor(
    readonly teamKey: string,
    readonly amount: number,
  ) {}
}

export class RemoveUserFromTeam {
  static type = '[Console] Remove user';

  constructor(
    readonly teamKey: string,
    readonly userKey: string,
  ) {}
}

export class CancelUserInvite {
  static type = '[Console] Cancel User Invite';

  constructor(
    readonly teamKey: string,
    readonly email: string,
  ) {}
}

export class ChangeUserRight {
  static type = '[Console] Change User Right';

  constructor(
    readonly teamKey: string,
    readonly user: UserItem,
    readonly rights: Rights,
  ) {}
}

export class SetSmsSenderName {
  static type = '[Console] Set Sms Sender name';

  constructor(
    readonly teamKey: string,
    readonly senderName: string,
  ) {}
}

export class SwitchConsoleTeam {
  static type = '[Console] Switch Team';

  constructor(readonly consoleTeam: TeamData | typeof CONSOLE_ZEFFI_TEAM) {}
}

export class GetParentConsoleTeam {
  static type = '[Console] Get Parent Console Team';

  constructor(readonly team: string) {}
}

export class UpdateParentSettings {
  static readonly type = '[Console] Update Parent Settings';

  constructor(
    readonly teamKey: string,
    readonly update: Partial<TeamParentSettings>,
  ) {}
}

export class UpdateSignupSettings {
  static readonly type = '[Console] Update Signup Settings';

  constructor(
    readonly teamKey: string,
    readonly update: Partial<SignupData>,
  ) {}
}

export class GetParentTeams {
  static readonly type = '[Console] Get Parent Teams';
}

export class UpdateFeedbackSettings {
  static readonly type = '[Console] Update Feedback Settings';

  constructor(
    readonly teamKey: string,
    readonly update: Partial<FeedbackSettings>,
  ) {}
}

export class DeleteFeedbackSettings {
  static readonly type = '[Console] Update Feedback Settings';

  constructor(
    readonly teamKey: string,
    readonly updateKey: string,
  ) {}
}
