import { forkJoin } from 'rxjs';
import { timeout, map } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { LocalStorage } from 'ngx-webstorage';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { AccountState } from '@shared/states/account.state';
import { AuthState } from '@shared/states/auth.state';
import { AIModelDependentMessage } from '@shared/utilities/string.utilities';

/**
 * This service will calculate z-scores for the individual users
 */
@Injectable({
  providedIn: 'root',
})
export class ReportAssistant {
  @LocalStorage('consent') trackingAllowed: boolean | undefined;

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  public getClusters(surveyKey: string, reportKey: string, language: string, items: any, backgroundInfo: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/whyFinderThemes`;
    const itemsCount = items?.strengths?.length + items?.weaknesses?.length;
    let strPrcnt = items?.strengths?.length / itemsCount;
    strPrcnt = isNaN(strPrcnt) ? 0.5 : strPrcnt;

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    const request: (k: number) => any = (k) =>
      this.http
        .post(
          url,
          {
            temperature: 0.2,
            // model: 3,
            modelKey: 'gpt-4o-mini',
            seed: 1,
            functionName: 'getWhyFinderThemes',
            messages: [
              {
                role: 'user',
                message: JSON.stringify({
                  strengths: items?.strengths
                    ?.map((v) => [v, Math.random()])
                    .sort((a: [any, number], b: [any, number]) => a[1] - b[1])
                    .map((v) => v[0])
                    .slice(0, 100 * strPrcnt),
                  weaknesses: items?.weaknesses
                    ?.map((v) => [v, Math.random()])
                    .sort((a: [any, number], b: [any, number]) => a[1] - b[1])
                    .map((v) => v[0])
                    .slice(0, 100 * (1 - strPrcnt)),
                }),
              },
            ],
            parameters: {
              system: [language, backgroundInfo, k],
              function: [language],
            },
          },
          {
            headers,
            withCredentials: this.trackingAllowed || false,
          },
        )
        .pipe(
          timeout(300000),
          // catchError((error: any) => {
          //   if (error && error.status !== 403) {
          //     this.dc.openErrorDialog(
          //       $localize`:@@zef-i18n-00040:Server Error`,
          //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
          //     );
          //   }

          //   return throwError(error);
          // }),
        );

    const requests =
      itemsCount > 100
        ? Array.from({ length: 7 }, () => request(10))
        : itemsCount > 75
          ? Array.from({ length: 4 }, () => request(7))
          : itemsCount > 20
            ? Array.from({ length: 3 }, () => request(5))
            : Array.from({ length: 3 }, () => request(3));

    return forkJoin(requests);
  }

  public assign_themes(surveyKey: string, reportKey: string, items: string[], themes: string[]) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/semantic/assign_themes`;

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    const chunkSize: number = 200;
    const requests = items
      .reduce((result, item, index) => {
        if (index % chunkSize === 0) {
          result.push(items.slice(index, index + chunkSize));
        }
        return result;
      }, [])
      .map((chunk: string[]) =>
        this.http
          .post(
            url,
            {
              data: {
                items: chunk,
                themes,
              },
            },
            {
              headers,
              withCredentials: this.trackingAllowed || false,
            },
          )
          .pipe(timeout(300000)),
      );

    return forkJoin(requests).pipe(
      map((res: any[]) => ({ assignedThemes: res?.map((resItem) => resItem?.assignedThemes).flat() || [] })),
    );
  }

  public finalizeClusters(
    surveyKey: string,
    reportKey: string,
    language: string,
    items: string,
    backgroundInfo: string,
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/whyFinderFinalizedThemes`;
    const data: { [prop: string]: any } = {
      temperature: 0.4,
      modelKey: 'gpt-4o',
      functionName: 'getWhyFinderFinalizedThemes',
      messages: [{ role: 'user', message: items }],
      parameters: {
        function: [language, backgroundInfo],
        system: [language, backgroundInfo],
      },
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        // catchError((error: any) => {
        //   if (error && error.status !== 403) {
        //     this.dc.openErrorDialog(
        //       $localize`:@@zef-i18n-00040:Server Error`,
        //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
        //     );
        //   }

        //   return throwError(error);
        // }),
      );
  }

  public getImportance(reportKey: string, language: string, items: string, backgroundInfo: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/whyFinderImportances`;
    const data: { [prop: string]: any } = {
      temperature: 0.7,
      model: 4,
      functionName: 'getWhyFinderImportances',
      messages: [{ role: 'user', message: items }],
      parameters: {
        function: [language, backgroundInfo],
        system: [language, backgroundInfo],
      },
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        // catchError((error: any) => {
        //   if (error && error.status !== 403) {
        //     this.dc.openErrorDialog(
        //       $localize`:@@zef-i18n-00040:Server Error`,
        //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
        //     );
        //   }

        //   return throwError(error);
        // }),
      );
  }

  public getWhyFinderTextSummary(
    surveyKey: string,
    reportKey: string,
    language: string,
    backgroundInfo: string,
    message: { role: string; message: string },
    baseStateMessages: { role: string; message: string }[] = [],
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/${
      baseStateMessages?.length ? 'whyFinderTextSummaryFiltered' : 'whyFinderTextSummary'
    }`;
    const data: { [prop: string]: any } = {
      temperature: 0.4,
      modelKey: 'gpt-4o-mini',
      messages: [...baseStateMessages, ...[message]],
      maxTokens: 1024,
      parameters: {
        system: [language, backgroundInfo],
      },
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        // catchError((error: any) => {
        //   if (error && error.status !== 403) {
        //     this.dc.openErrorDialog(
        //       $localize`:@@zef-i18n-00040:Server Error`,
        //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
        //     );
        //   }

        //   return throwError(error);
        // }),
      );
  }

  public getShortenedChartNames(surveyKey: string, reportKey: string, language: string, items: any[]) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/chartNameShortener`;
    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');
    const data: { [prop: string]: any } = {
      temperature: 0.4,
      modelKey: 'gpt-4o-mini',
      functionName: 'getShortenedChartNames',
      messages: [
        {
          role: 'user',
          message: JSON.stringify(items),
        },
      ],
      parameters: {
        system: [language],
        function: [language],
      },
    };

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        // catchError((error: any) => {
        //   if (error && error.status !== 403) {
        //     this.dc.openErrorDialog(
        //       $localize`:@@zef-i18n-00040:Server Error`,
        //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
        //     );
        //   }

        //   return throwError(error);
        // }),
      );
  }

  public getCrossNPSInsights(surveyKey: string, reportKey: string, language: string, items: any) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/crossNPSInsights`;
    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');
    const data: { [prop: string]: any } = {
      temperature: 0.4,
      modelKey: 'gpt-4o-mini',
      maxTokens: 1024,
      messages: [
        {
          role: 'user',
          message: JSON.stringify(items),
        },
      ],
      parameters: {
        system: [language],
        function: [language],
      },
    };

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        // catchError((error: any) => {
        //   if (error && error.status !== 403) {
        //     this.dc.openErrorDialog(
        //       $localize`:@@zef-i18n-00040:Server Error`,
        //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
        //     );
        //   }

        //   return throwError(error);
        // }),
      );
  }

  public getSummaryAveragesTextSummary(
    surveyKey: string,
    reportKey: string,
    properties: [
      language: string,
      averageNumber: string,
      questionCount: string,
      sliderScale: string,
      questionData: string,
      groupData: string,
      customInstructions: string,
    ],
    messages: { role: string; message: string }[] = [],
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/esko/summaryAveragesTextSummary`;
    const data: { [prop: string]: any } = {
      temperature: 0.4,
      modelKey: 'vertexai-claude-3-5-sonnet@20240620',
      secondaryModelKey: 'gpt-4o-latest',
      messages,
      maxTokens: 1024,
      parameters: {
        user: properties,
      },
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Survey-Key', surveyKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        map((res: any) => AIModelDependentMessage(res)),
        // catchError((error: any) => {
        //   if (error && error.status !== 403) {
        //     this.dc.openErrorDialog(
        //       $localize`:@@zef-i18n-00040:Server Error`,
        //       $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
        //     );
        //   }

        //   return throwError(error);
        // }),
      );
  }
}
